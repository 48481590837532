<template>
	<nav class="navbar-tbfdigital">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Untranslated', [])" v-if="globalTranslate">
			Untranslated
		</div>

		<div class="space-left">
			<img src="/build/icons/daily-plan.svg" class="main-page-icon" v-if="$route.name == 'homepage' || $route.name == 'dashboard'" />
			<img src="/build/icons/master-goals.svg" class="main-page-icon" v-else-if="$route.name == 'master-goals'" />
			<img src="/build/icons/objectives.svg" class="main-page-icon" v-else-if="$route.name == 'objectives'" />
			<img src="/build/icons/people.svg" class="main-page-icon" v-else-if="$route.name == 'users'" />
			<img src="/build/icons/reports.svg" class="main-page-icon" v-else-if="$route.name == 'reports'" />
			<img src="/build/icons/back-cta.svg" class="back-btn" @click="goRoute(navbarBack)" v-else-if="navbarBack" />
		</div>
		<div class="content-section">
			<div
				class="title-page"
				v-if="viewTitle"
				:class="{ 'fw-500': $route.name == 'software-updates' || $route.name == 'software-updates-show' }">
				{{ navbarTitle }}
			</div>
			<div v-if="navbarDescription && viewTitle">
				<v-popover
					offset="10"
					trigger="hover click"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					popoverClass="description-popover"
					:delay="{ show: 100, hide: 0 }"
					class="margin-left-desc">
					<icon-info class="icon-info" />

					<template slot="popover">
						<div class="description">{{ navbarDescription }}</div>
					</template>
				</v-popover>
			</div>
			<div class="title-page" v-if="!viewTitle"><div class="placeholder-loader" style="height: 22px; width: 170px"></div></div>

			<div class="profile-items">
				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					:delay="{ show: 100, hide: 0 }"
					v-if="$auth.ready() && $auth.user().instance && $auth.user().instance.cancel_at_period_end">
					<div class="cancel-subscription-notification item-navbar">
						<div class="icon" v-if="$resize && $mq.below(780)"><icon-atention /></div>
						<div class="text" v-else>
							{{
								$t("navbar.cancel_subscription_short", {
									date: moment($auth.user().instance.cancel_at_period_end).format("DD MMM YYYY"),
								})
							}}
						</div>
					</div>

					<template slot="popover">
						<div class="simple-text">
							{{
								$t("navbar.cancel_subscription_notification", {
									date: moment($auth.user().instance.cancel_at_period_end).format("DD MMMM YYYY"),
								})
							}}
						</div>
					</template>
				</v-popover>

				<div
					class="grace-period-notification item-navbar"
					v-if="$auth.ready() && $auth.user().rights.instance == 'grace_period'"
					@click="showModal('payment_error')">
					<div class="icon" v-if="$resize && $mq.below(780)"><icon-info /></div>
					<div class="text" v-else>{{ $t("navbar.grace_period_notification") }}</div>
				</div>

				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					class="item-navbar"
					:delay="{ show: 100, hide: 0 }"
					v-if="showTimerTask">
					<div class="timer-task-navbar" @click="openTaskModal()">
						<div class="record-icon"></div>
						<div class="text">{{ convertSecondsToHMS(timerTask.seconds) }}</div>
					</div>
					<template slot="popover">
						<div class="simple-text">Task: {{ timerTask.name }}</div>
					</template>
				</v-popover>

				<!-- <div class="next-webinar item-navbar" @click="showModal('webinar_tbf')">
					<div class="label">{{ $t('navbar.webinar') }}</div>
					<div class="date" v-if="$resize && $mq.above(779)">{{ getTextForWebinar() }}</div>
				</div> -->

				<template v-if="meetings.length">
					<v-popover
						ref="popoverMeetings"
						offset="5"
						trigger="click"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						class="label"
						:delay="{ show: 100, hide: 0 }">
						<div class="meetings-navbar green item-navbar">
							<div class="label">{{ $tc("navbar.more_meetings_today", meetings.length) }}</div>
							<div class="meeting-live" v-if="startedMeetings.length">
								<icon-record />
							</div>
						</div>

						<template slot="popover">
							<div class="list-meetings">
								<template v-if="startedMeetings.length">
									<div class="title">{{ $t("navbar.meetings_started") }}</div>
									<div class="item" v-for="meeting in startedMeetings" @click="closePopover()">
										<a class="text" :href="`${organigram_fe_link}/meeting/${meeting.session_link}`">
											{{ meeting.name }}
										</a>
										<div class="icon red">
											<icon-record />
										</div>
									</div>
								</template>

								<template v-if="awaitingMeetings.length">
									<div class="title">{{ $t("navbar.meetings_await") }}</div>
									<div class="item" v-for="meeting in awaitingMeetings" @click="closePopover()">
										<a
											class="text"
											:href="`${organigram_fe_link}/dashboard?meetingId=${meeting.id}&meetingDate=${moment().format(
												'DD-MM-YYYY'
											)}`">
											{{ meeting.name }}
										</a>
										<div class="icon yellow">
											<icon-bell />
										</div>
									</div>
								</template>

								<template v-if="nextMeetings.length">
									<div class="title">{{ $t("navbar.next_meetings") }}</div>
									<div class="item" v-for="meeting in nextMeetings" @click="closePopover()">
										<a
											class="text"
											:href="`${organigram_fe_link}/dashboard?meetingId=${meeting.id}&meetingDate=${moment().format(
												'DD-MM-YYYY'
											)}`">
											{{ meeting.name }}
										</a>
									</div>
								</template>
							</div>
						</template>
					</v-popover>
				</template>

				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					class="mission-vision item-navbar"
					:delay="{ show: 100, hide: 0 }">
					<button class="btn-navbar only-icon" @click="showModal('mission_vision')">
						<div class="icon"><icon-superman /></div>
					</button>

					<template slot="popover">
						<div class="simple-text">{{ $t("company.show_mission_vision") }}</div>
					</template>
				</v-popover>

				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					class="play-procedures item-navbar"
					:delay="{ show: 100, hide: 0 }"
					v-if="soft_procedures.is_active">
					<button class="btn-navbar only-icon" @click="showModal('procedure_check')">
						<div class="icon"><icon-learn /></div>
					</button>

					<template slot="popover">
						<div class="simple-text">{{ $t("navbar.procedures") }}</div>
					</template>
				</v-popover>

				<v-popover
					class="help item-navbar"
					offset="2"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf btn-animated"
					:delay="{ show: 0, hide: 0 }"
					v-if="helpData">
					<a class="btn-navbar only-icon" :href="helpData.link" target="_blank">
						<div class="icon"><icon-question-circle /></div>
					</a>

					<template slot="popover">
						<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
					</template>
				</v-popover>

				<div
					class="item-navbar"
					v-if="$auth.ready() && $auth.user().status && ['active', 'grace_period'].includes($auth.user().status)">
					<button class="btn-navbar only-icon" @click="showModal('notifications')">
						<div class="icon active" v-if="notifications.length"><icon-notifications-on /></div>
						<div class="icon" v-else><icon-notifications /></div>
					</button>
				</div>

				<div class="profile-navbar dropdown item-navbar" v-if="$auth.ready() && $auth.check()">
					<div class="has_notification" v-if="$auth.user().new_software_updates || $auth.user().new_software_wip"></div>

					<img
						:src="$auth.user().avatar"
						ref="dropdownUser"
						id="dropdownUser"
						data-toggle="dropdown"
						v-if="$auth.user().avatar" />
					<div class="user-circle" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-else>
						<icon-user-settings />
					</div>

					<div
						class="dropdown-menu dropdown-menu-right"
						id="dropdownApplications"
						aria-labelledby="dropdownApplications"
						v-if="$auth.ready()">
						<div class="user-data">
							<div class="name">{{ $auth.user().first_name + " " + $auth.user().last_name }}</div>
							<div class="email">{{ $auth.user().email }}</div>
						</div>
						<div class="dropdown-item">
							<a :href="`${organigram_fe_link}/settings/profile`">
								<div class="text">{{ $t("navbar.account") }}</div>
							</a>
						</div>
						<div class="dropdown-item">
							<a :href="`${organigram_fe_link}/software-updates`">
								<div class="text" :class="{ new: $auth.user().new_software_updates }">
									{{
										$tc("navbar.software_updates", $auth.user().new_software_updates, {
											count: $auth.user().new_software_updates,
										})
									}}
								</div>
							</a>
						</div>
						<div class="dropdown-item">
							<a :href="`${organigram_fe_link}/work-in-progress`">
								<div class="text" :class="{ new: $auth.user().new_software_wip }">
									{{
										$tc("navbar.work_in_progress", $auth.user().new_software_wip, {
											count: $auth.user().new_software_wip,
										})
									}}
								</div>
							</a>
						</div>
						<div
							class="dropdown-item"
							v-if="$auth.user().instance_id && ['active', 'grace_period'].includes($auth.user().status)">
							<a href="https://help.tbf.ro" target="_blank">
								<div class="text">{{ $t("navbar.help_center") }}</div>
							</a>
						</div>
						<div class="dropdown-item" @click="showModal('contact')">
							<div class="text">{{ $t("navbar.contact") }}</div>
						</div>
						<div class="dropdown-item">
							<div v-if="$auth.impersonating()" @click="redirectToCentral">
								<div class="text">Impersonate: {{ $auth.user().first_name }} {{ $auth.user().last_name }}</div>
								<div class="text">Revino la Central</div>
							</div>

							<div class="text" @click="$auth.logout()" v-else>{{ $t("navbar.log_out") }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
	</nav>
</template>

<script>
import IconBell from "../Icons/Bell";
import IconInfo from "../Icons/Info";
import IconArrow from "../Icons/Arrow";
import IconUserSettings from "../Icons/UserSettings";
import IconDotsSquare from "../Icons/DotsSquare";
import LoaderApplications from "../PagesLoaders/ApplicationsNavbar.vue";
import IconSuperman from "../Icons/Superman";
import IconQuestionCircle from "../Icons/QuestionCircle";
import IconNotifications from "../Icons/Notifications";
import IconNotificationsOn from "../Icons/NotificationsOn";
import IconApplications from "../Icons/Applications";
import IconLearn from "../Icons/LearnProcedures";
import IconRecord from "../Icons/Record";

export default {
	data() {
		return {
			notifications: [],
			applications: [],
			navbarTitle: "",
			navbarDescription: "",
			navbarBack: "",
			isSocketActive: false,
			viewTitle: true,
			helpData: this.$store.getters["help_links/currentLink"](this.$route.name),
			organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
			procedureApplication: {},
			meetings: [],
			nextMeetings: [],
			awaitingMeetings: [],
			startedMeetings: [],
			timerTask: {},
			showTimerTask: false,
			intervalTimer: {},
		};
	},
	components: {
		IconBell,
		IconArrow,
		IconInfo,
		IconUserSettings,
		IconDotsSquare,
		LoaderApplications,
		IconSuperman,
		IconQuestionCircle,
		IconApplications,
		IconNotifications,
		IconNotificationsOn,
		IconLearn,
		IconRecord,
	},
	computed: {
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
	},
	watch: {
		$route(to, from) {
			if (from.name != to.name) {
				this.viewTitle = false;
			}
			this.helpData = this.$store.getters["help_links/currentLink"](to.name);
		},
	},
	created() {
		this.$root.$on("navbar_title", (title, back = false, description = false) => {
			this.viewTitle = true;
			this.navbarTitle = title;
			this.navbarBack = back;
			this.navbarDescription = description;
		});
	},
	async mounted() {
		if (!this.isSocketActive && this.$auth.check()) {
			this.initWebSocket();
		}

		await this.getApplications();
		this.$root.$on("navbar_title", (title, back = false, description = false) => {
			this.navbarTitle = title;
			this.navbarBack = back;
			this.navbarDescription = description;
		});

		this.$root.$on("clear_notifications", () => {
			this.notifications = [];
		});

		await this.getTimerTask();
		await this.getTodayMeetings();
	},
	methods: {
		getApplications() {
			var refreshIntervalApps = setInterval(() => {
				if (this.$store.state.applications.applications.length) {
					clearInterval(refreshIntervalApps);
					this.applications = this.$store.state.applications.applications;
					this.procedureApplication = this.$store.getters["applications/getApplication"]("Proceduri");
				}
			}, 200);
		},
		initAppcues() {
			window.Appcues.identify(
				this.$auth.user().id, // unique, required
				{
					createdAt: null, // Unix timestamp of user signup date
					purchasedAd: null, // Unix timestamp of account purchase date (leave null if empty)
					planTier: this.$auth.user().package ? this.$auth.user().package.name : "", // Current user’s plan tier
					role: this.$auth.user().is_admin ? "admin" : "employee", // Current user’s role or permissions
					accountId: this.$auth.user().id, // Current user's account ID
					firstName: this.$auth.user().last_name, // current user's first name

					// additional suggestions
					email: this.$auth.user().email, // Current user's email
					version: "2.0", // users on different versions may need to see different content
					language: this.$auth.user().language, // for multi-language applications
				}
			);
		},
		initWebSocket() {
			this.socket = new WebSocket(process.env.VUE_APP_WSS_LINK_NOTIFICATION);

			this.socket.onopen = (event) => {
				// console.log('Connection to websocket established');

				this.isSocketActive = true;

				// check in to websocket
				this.socket.send(
					JSON.stringify({
						user_id: this.$auth.user().id,
						type: "notification",
						soft: "objective",
					})
				);
				// console.log('Subscribed to websocket');
			};

			// when receiving a message
			this.socket.onmessage = ({ data }) => {
				const parsedData = JSON.parse(data);
				this.notifications.unshift(parsedData);
				this.$root.$emit("updateNotificationsList", parsedData);
			};

			this.socket.onerror = (error) => {
				console.log(`Websocket error`);
			};

			this.socket.onclose = (event) => {
				if (this.isSocketActive) {
					this.isSocketActive = false;
				}

				if (event.wasClean) {
					// console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
				} else {
					// e.g. server process killed or network down
					// event.code is usually 1006 in this case
					// console.log('[close] Connection died');

					setTimeout(() => {
						this.initWebSocket();
					}, 600000);
				}
			};
		},
		logout() {
			this.$auth.logout();
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		viewObjective(objectiveSlug) {
			this.$router.push({ name: "show-objective", params: { slug: objectiveSlug } });
		},
		goRoute(back) {
			if (back.slug) {
				this.$router.push({ name: back.route, params: { slug: back.slug } });
			} else {
				this.$router.push({ name: back.route });
			}
		},
		getTextForWebinar() {
			let date = "";
			let currentHour = moment(moment().tz("Europe/Bucharest").format("YYYY-MM-DD HH:mm"));
			let webinarEndHour = moment("11:00", "HH:mm").tz("Europe/Bucharest");

			var webinarStartHour = moment.tz("Europe/Bucharest").hours(10).startOf("hour");
			var localtz = moment.tz.guess();
			var dateClone = webinarStartHour.clone().tz(localtz);
			var formatDate = moment(dateClone).format("HH:mm");

			if (moment().isoWeekday() == 2 && currentHour.isAfter(webinarEndHour)) {
				date = `${this.$t("calendar.long_days.tuesday")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			}
			// else if(moment().isoWeekday() == 4 && currentHour.isAfter(webinarEndHour)) {
			// 	date = `${this.$t('calendar.long_days.tuesday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
			// }
			else if (moment().isoWeekday() == 2 && currentHour.isBefore(webinarEndHour)) {
				date = `${this.$t("webinar_tbf.today")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			} else if (moment().isoWeekday() == 1) {
				date = `${this.$t("webinar_tbf.tomorrow")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			} else if (moment().isoWeekday() > 2) {
				date = `${this.$t("calendar.long_days.tuesday")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			}
			// else if(moment().isoWeekday() > 2 && moment().isoWeekday() < 4) {
			// 	date = `${this.$t('calendar.long_days.thursday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
			// }

			return date;
		},
		getTodayMeetings() {
			axios
				.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/instance/${this.$auth.user().master_instance_id}/today-sessions`)
				.then(({ data }) => {
					this.meetings = data.data;
					this.startedMeetings = data.data.filter((el) => el.status == "ongoing");
					this.nextMeetings = data.data.filter(
						(el) => el.status != "ongoing" && moment() < moment(`${el.date} ${el.start_time}`)
					);
					this.awaitingMeetings = data.data.filter(
						(el) => el.status != "ongoing" && moment() > moment(`${el.date} ${el.start_time}`)
					);
				});
		},
		closePopover() {
			const popover = this.$refs.popoverMeetings;
			popover.hide();
		},
		redirectToCentral() {
			window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/dashboard`);
		},
		async getTimerTask() {
			this.showTimerTask = false;
			clearInterval(this.intervalTimer);

			await axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/user/last-task-timer`).then(({ data }) => {
				if (data.data) {
					this.timerTask = data.data;

					this.intervalTimer = setInterval(() => {
						this.timerTask.seconds += 1;
					}, 1000);

					setTimeout(() => {
						this.showTimerTask = true;
					}, 200);
				}
			});
		},

		convertSecondsToHMS(seconds) {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const secondsRemaining = seconds % 60;
			const formattedHours = hours.toString().padStart(2, "0");
			const formattedMinutes = minutes.toString().padStart(2, "0");
			const formattedSeconds = secondsRemaining.toString().padStart(2, "0");
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},
		openTaskModal() {
			window.location.href = `${this.organigram_fe_link}/dashboard?open_task=${this.timerTask.task_id}&date=${this.timerTask.date}`;
		},
	},
};
</script>
