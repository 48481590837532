<template>
	<div class="modal-tbf evaluate-promise">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Evaluate Promise', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="container-modal form-modal" v-if="loaded">
			<div class="header-modal">
				<div class="title">{{ $t("daily_promise.evaluate_title") }}</div>
				<div class="actions" @click="closeModal">
					<div class="btn-default-tbf close-btn"><icon-plus class="icon-close" /></div>
				</div>
			</div>
			<div class="promise-date">{{ $t("daily_promise.date") }} {{ moment(promise.created_at).format("DD MMMM, YYYY") }}</div>
			<div class="description-modal">{{ promise.name }}</div>
			<div class="contribute-to" v-if="promise.key_results_names">{{ $t("set-promise.contribute-to") }}:</div>
			<div class="key-results" v-if="promise.key_results_names">
				<div class="contribute-to-kr" v-for="(krn, index) in promise.key_results_names.split(',')">{{ index + 1 + ". " + krn }}</div>
			</div>
			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="submit-form" v-if="promise.key_results_names">
							<button class="button-tbf-red button-submit" @click="openReason">
								<div class="text">{{ $t("daily_promise.evaluate_no") }}</div>
							</button>
							<button class="button-tbf-blue button-submit" @click="storePromise(true)">
								<div class="text">{{ $t("daily_promise.evaluate_yes") }}</div>
							</button>
						</div>
						<div class="submit-form" v-else>
							<div class="alert-tbf red mb-0">
								<div class="icon"><img src="/build/images/warning-square.svg" /></div>
								<div class="data">
									<div class="title">{{ $t("daily_promise.cant_evaluate") }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="submodal-container" v-if="subModalShow" id="promise-submodal-container">
				<div class="overlay-submodal"></div>
				<confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
				<reason-evaluation v-if="show_subm_modal.reason_evaluation" @send_response="saveResponseFailed" />
			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-100">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px"></div>
						</div>
						<div class="input-group-tbf w-mob-100">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px"></div>
						</div>
						<div class="submit-form">
							<div class="placeholder-loader" style="width: 250px; height: 40px"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import IconPlus from "../Icons/Plus";
import IconInfo from "../Icons/Info";
import IconList from "../Icons/List";
import IconArrow from "../Icons/Arrow";
import IconPeople from "../Icons/People";
import IconCircleCheck from "../Icons/CircleCheck";
import IconTags from "../Icons/Tags";

import ConfirmClose from "../SubModals/ConfirmClose";
import ReasonEvaluation from "../SubModals/ReasonEvaluation";
import { required, email } from "vuelidate/lib/validators";

export default {
	data() {
		return {
			loaded: false,
			editAction: false,
			promise: {},
			evaluate_promise: { name: "", id: "" },
			activeTooltip: "",
			subModalShow: false,
			show_subm_modal: {
				confirm_close: false,
				reason_evaluation: false,
			},
			arrayTranslations: ["daily_promise.evaluate_title", "daily_promise.date", "set-promise.contribute-to", "daily_promise.evaluate_no", "daily_promise.evaluate_yes"],
		};
	},
	props: {
		model: Object,
		fromPage: String,
	},
	components: {
		IconPlus,
		IconInfo,
		IconPeople,
		IconCircleCheck,
		IconArrow,
		IconTags,
		IconList,
		ConfirmClose,
		ReasonEvaluation,
	},
	async mounted() {
		if (this.model == "") {
			await this.getUserPromise();
		} else {
			this.promise = this.model;
			this.loaded = true;
		}

		this.$root.$on("confirm_close", () => {
			this.subModalShow = true;

			if (this.show_subm_modal.reason_evaluation) {
				$("#submodal_reason_evaluation").removeClass("active");
				setTimeout(() => {
					this.show_subm_modal.reason_evaluation = false;
					this.show_subm_modal.confirm_close = true;
					setTimeout(() => {
						$("#promise-submodal-container").addClass("active");
						setTimeout(() => {
							$("#submodal_confirm_close").addClass("active");
						}, 200);
					}, 0);
				}, 200);
			} else {
				this.show_subm_modal.confirm_close = true;
				setTimeout(() => {
					$("#promise-submodal-container").addClass("active");
					setTimeout(() => {
						$("#submodal_confirm_close").addClass("active");
					}, 200);
				}, 0);
			}
		});

		if (this.fromPage == "key_result_modals") {
			this.$root.$emit("visibilityHiddenKeyResultPromises", true);
		}
	},
	methods: {
		async getUserPromise() {
			await axios
				.get("/today-promise")
				.then(({ data }) => {
					this.promise = data.data.today_promise;
				})
				.then(() => {
					this.loaded = true;
				});
		},
		closeModal() {
			this.$emit("toggle_modal");
		},
		closeModalConfirmed() {
			if (this.fromPage == "key_result_modals") {
				this.$root.$emit("visibilityHiddenKeyResultPromises", true);
			}
			this.$emit("toggle_modal");
		},
		cancelClose() {
			$("#submodal_confirm_close").removeClass("active");
			setTimeout(() => {
				$("#promise-submodal-container").removeClass("active");
				setTimeout(() => {
					this.subModalShow = false;
					this.show_subm_modal.confirm_close = false;
				}, 200);
			}, 200);
		},
		saveResponseFailed(value) {
			this.storePromise(false, value);
		},
		storePromise(promise_state, reason) {
			axios
				.patch("/promises/" + this.promise.id + "/evaluate", {
					accomplished: promise_state,
					why_not: promise_state ? null : reason,
				})
				.then(({ data }) => {
					if (this.fromPage == "show_user") {
						this.$root.$emit("refreshUserPage");
					} else if (this.fromPage == "key_result_modals") {
						this.$root.$emit("refreshKeyResultPromises");
						this.$root.$emit("refreshUserPage");
					}
					this.$emit("toggle_modal");
				});
		},
		showModal(type, model = false, requiredData = false, userData = false) {
			if (model) {
				this.hideDropdown();
			}
			this.$root.$emit("open_modal", type, model, requiredData, userData, "user");
		},
		openReason() {
			this.subModalShow = true;
			this.show_subm_modal.reason_evaluation = true;
			setTimeout(() => {
				$("#promise-submodal-container").addClass("active");
				setTimeout(() => {
					$("#submodal_reason_evaluation").addClass("active");
				}, 200);
			}, 0);
		},
	},
};
</script>
