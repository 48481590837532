<template>
	<div class="modal-tbf">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Objective', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-with-help">
				<div class="header-modal with-help">
					<div class="title">
						{{ editAction ? $t('objective.title_edit') : $t('objective.title_new') }}
					</div>
					<div class="actions">
						<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
							<a class="btn-tbf blue only-icon help-btn" :href="helpData1.link" target="_blank" v-if="helpData1">
								<div class="icon"><icon-question /></div>
							</a>

							<template slot="popover">
								<div class="simple-text">{{ $t(`help_links.${helpData1.type}`) }}</div>
							</template>
						</v-popover>

						<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
					</div>
				</div>
				<div class="help-text">
					{{ $t('help_links.objective_need_help')}}
					<a :href="helpData2.link" target="_blank">{{ $t(`help_links.${helpData2.type}`) }}</a> |
					<a :href="helpData3.link" target="_blank">{{ $t(`help_links.${helpData3.type}`) }}</a>
				</div>
			</div>

			<div class="info-content">
				<transition name="slide" mode="out-in">
					<div class="container-content-modal" v-if="activeSlide == 'main_form'">
						<div class="form-section">
							<div class="input-group-tbf w-mob-100" v-bind:class="{has_error: $v.objective.name.$error, complete: objective.name != '' && !$v.objective.name.$error, 'view-tooltip': activeTooltip == 'obj_name', 'w-tbf-50': viewSelectUser}">
								<div class="label-input">
									<label>{{ $t('objective.name') }}*</label>
									<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
								</div>
								<div class="input-box">
									<div class="icon-left"><icon-folder /></div>
									<div class="icon-right" v-if="objective.name != ''" @click.stop="objective.name = ''"><icon-plus class="icon-clear"/></div>
									<input type="text" :placeholder="$t('objective.placeholder_name')" class="input-text" v-model="objective.name" ref="first_input">
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.selectedUser.$error, complete: selectedUser != '' && !$v.selectedUser.$error, 'view-tooltip': activeTooltip == 'obj_user'}" v-if="viewSelectUser" id="user_required">
								<div class="label-input">
									<label>{{$t('objective.user')}}*<span class="add-button-in" v-if="$auth.user().rights.can_create" @click="$auth.user().rights.can_create_user ? showModal('user') : openModalV2('cant_create_more', false, 'users')">{{ $t('key-result.form.add_user') }}</span>
									</label>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-people /></div>
									<multiselect 
										v-model="selectedUser"
										class="select-tags-tbf"
										:options="optionsUsers"
										:allow-empty="false"
										:show-labels="false"
										track-by="id" 
										label="first_name"
										:custom-label="fullName"
										@open="dropdownIsOpen('users')"
										@close="dropdownIsOpen('')"
										@input="handleUserSelected"
										>
										<template slot="placeholder" slot-scope="props">
											<span class="text">
												{{ $t('objective.placeholder_user') }}
											</span>
										</template>
										<template slot="singleLabel" slot-scope="props">
											<div class="option__image">
												<span>
													{{ props.option.first_name + ' ' + props.option.last_name }}
												</span>
											</div>
										</template>
										<template slot="option" slot-scope="props">
											<div class="option__image">
												<img :src="props.option.avatar" v-if="props.option.avatar">
												<img v-else src="/build/images/profile-default.svg">
												<span>
													{{ props.option.first_name + ' ' + props.option.last_name }}
												</span>
											</div>
										</template>
										<template slot="noResult">{{ $t('objective.no-results') }}</template>
										<template slot="noOptions">{{ $t('general.empty-list') }}</template>
									</multiselect>
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{complete: selectedMasterGoal != '', 'view-tooltip': activeTooltip == 'obj_master_goal'}">
								<div class="label-input">
									<label>
										{{ $t('objective.master_goal') }}
										<span class="add-button-in" v-if="$auth.user().rights.can_create" @click="showModal('master_goal')">
											{{ $t('master_goal.title_new') }}
										</span>
									</label>
									<div v-if="errorsBe.master_goal_id" class="error-msg">{{ errorsBe.master_goal_id.join(" | ") }}</div>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-master-goals /></div>
									<multiselect 
									v-model="selectedMasterGoal"
									class="select-tags-tbf"
									:options="optionsMasterGoals"
									:allow-empty="true"
									:show-labels="false"
									track-by="id" 
									label="name"
									@open="dropdownIsOpen('mastergoal')"
									@close="dropdownIsOpen('')"
									>
										<template slot="placeholder" slot-scope="props">
											<span class="text">
												{{$t('objective.placeholder_vision')}}
											</span>
										</template>
										<template slot="option" slot-scope="props">
											<div :class="'option-mg level-' + props.option.level_depth" :style="'padding-left: calc(10px * ' + props.option.level_depth + ');'">
												<div class="icon-level" v-if="props.option.level_depth != 0"><icon-level-up /></div>
												<div class="'option_ellipsis">{{ props.option.name ? props.option.name : $t('master_goal.private') }}</div>
											</div>
										</template>
										<template slot="singleLabel" slot-scope="props">
											<div class="option_ellipsis">{{ props.option.name ? props.option.name : $t('master_goal.private') }}</div>
										</template>
										<template slot="noResult">{{ $t('objective.no-results') }}</template>
										<template slot="noOptions">{{ $t('general.empty-list') }}</template>
									</multiselect>	
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.selectedPrivacy.$error, complete: selectedPrivacy != '' && !$v.selectedPrivacy.$error, 'view-tooltip': activeTooltip == 'obj_privacy'}">
								<div class="label-input">
									<label>{{ $t('objective.privacy_type') }}</label>
								</div>

								<div class="input-box bor-0">
									<div class="privacy-objectives">
										<div class="item-privacy" v-for="option in optionsPrivacy" @click="selectedPrivacy = option.value" v-bind:class="{active: selectedPrivacy == option.value}">
											<icon-public v-if="option.key == 'public'"/>
											<icon-privat v-else-if="option.key == 'private'"/>
											<icon-personal v-else/>
											<div class="name">{{option.name}}</div>
										</div>
									</div>
								</div>
							</div>

							<div class="input-group-tbf" v-bind:class="{complete: objective.description != '', 'view-tooltip': activeTooltip == 'obj_description'}">
								<div class="label-input">
									<label>{{ $t('objective.description') }}</label>
									<div class="info-input" @click="activeTooltip == 'obj_description' ? activeTooltip = '' : activeTooltip = 'obj_description'">
										<icon-circle-check class="icon-check" v-if="objective.description != ''" />
										<!-- <icon-info class="icon-info" v-else/> -->
									</div>
								</div>
								<div class="input-box">
									<textarea class="input-textarea" :placeholder="$t('objective.placeholder_description')" v-model="objective.description" v-autosize rows="3"></textarea>
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50">
								<div class="label-input">
									<label>{{ $t('objective.reward')}}</label>
								</div>
								<div class="input-box">
									<div class="icon-left"><icon-award/></div>
									<div class="icon-right" v-if="objective.reward.name != null" @click.stop="objective.reward.name = null"><icon-plus class="icon-clear"/></div>
									<input type="text" :placeholder="$t('objective.reward_ph')" class="input-text" v-model="objective.reward.name">
								</div>
							</div>
							<div class="input-group-tbf w-mob-100 w-tbf-50">
								<div class="label-input reward" >
									<label>{{ $t('objective.reward_image')}}</label>
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf reward-popover" :delay="{show: 200, hide: 0}" v-if="objective.reward.filename">										
										<div class="simple-text">{{ $t('objective.view_photo') }}</div>

										<template slot="popover">
											<img :src="objective.reward.file_path" v-bind:class="{mrg_bot: objective.reward.name != null}" v-if="objective.reward.file_path">
											<div class="simple-text" v-if="objective.reward.name">{{ objective.reward.name }}</div>
										</template>
									</v-popover>
								</div>
								<div class="input-box upload_file_input" onclick="document.getElementById('fileUploaded').click();">
									<icon-upload class="icon-upload"/>
									<div class="placeholder" v-if="!fileUpload && (objective.reward.filename == null)">{{ $t('objective.add_image')}}</div>
									<div class="text" v-else> {{ fileUpload ? fileUpload.name : objective.reward.filename }}</div>
									<div class="icon-right" v-if="fileUpload || objective.reward.filename != null" @click.stop="onDeleteFile()"><icon-plus class="icon-clear"/></div>
									<input type="file" accept="image/*" id="fileUploaded" style="display:none" v-on:change="onFileChange">
								</div>

								<div v-if="fileError" class="error-msg custom-file-error">{{ fileError }}</div>
							</div>

							<div class="input-group-tbf" v-bind:class="{has_error: $v.objective.dateRange.start_date.$error, complete: objective.dateRange.start_date != '' && !$v.objective.dateRange.start_date.$error, disabled: disabledPeriod}">
								<div class="label-input">
									<label>{{ $t('objective.period') }}*</label>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-date /></div>
									<div class="icon-right" v-if="objective.dateRange.start_date != ''" @click.stop="clearRange"><icon-plus class="icon-clear"/></div>
									<div class="input-text-link" @click="disabledPeriod ? focusRequired('user_required') : showSubModal('calendar_period')">
										<div class="placeholder" v-if="objective.dateRange.start_date == ''">{{$t('key-result.form.select-days')}}</div>
										<div class="data-range" v-else>
											<div class="text">
												<span class="data">{{$t('key-result.form.start-date')}}: {{ objective.dateRange.start_date | moment('DD MMM YYYY') }}</span> - {{$t('key-result.form.end-date')}}: <span class="data"> {{ objective.dateRange.end_date | moment('DD MMM YYYY') }}</span>
											</div>
											<div class="total-days">{{ $tc('days', totalDaysRange) }}</div>
										</div>
									</div>
								</div>
							</div>

							<div class="submit-form">
								<button class="button-tbf-blue button-submit" @click="saveModifications">
									<div class="loader"></div>
									<div class="text">{{ editAction ? $t('objective.edit_button') : $t('objective.save_button') }}</div>
								</button>
							</div>
						</div>
					</div>
				</transition>
			</div>

			<div class="submodal-container" v-if="subModalShow" id="objective-submodal-container">
				<div class="overlay-submodal"></div>
				<confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose('confirm_close')" />
				<error-max-leaders v-if="show_subm_modal.error_max_leaders" @cancel_close="cancelClose('error_max_leaders')" />

				<objective-period-modal v-if="show_subm_modal.calendar_period"
				id="submodal_calendar_period"
				typeModal="objective"
				:objective="objective"
				:intervalDates="JSON.stringify(objective.dateRange)" 
				@intervalData="getIntervalData"
				:disabledDatesUser="disabledDatesUser"
				:userSelected="selectedUser ? selectedUser : null"
				@close_modal="closeSubModal('calendar_period')" />
			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px;"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px;"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>

						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="submit-form">
							<div class="placeholder-loader" style="width: 250px;height: 40px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPublic from "../Icons/Public"
	import IconPrivat from "../Icons/Privat"
	import IconPersonal from "../Icons/Personal"
	import IconPeople from "../Icons/People"
	import IconDate from "../Icons/Date"
	import IconMasterGoals from "../Icons/MasterGoals"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconPrivacySquare from "../Icons/PrivacySquare"
	import IconLevelUp from "../Icons/LevelUp"
	import IconQuestion from "../Icons/Question"
	import IconAward from "../Icons/Award"
	import IconUpload from "../Icons/Upload"

	import ObjectivePeriodModal from "../SubModals/CalendarPeriod"
	import ConfirmClose from "../SubModals/ConfirmClose"
	import ErrorMaxLeaders from "../SubModals/ErrorMaxLeaders"
	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				activeTooltip: '',
				totalDaysRange: 0,
				activeSlide: 'main_form',
				disabledPeriod: true,
				objective: {
					name: '',
					description: '',
					dateRange:{
						start_date: '',
						end_date: ''
					},
					reward: {
						name: null,
						filename: null
					},
				},
				selectedUser: '',
				objectiveOptions: [],
				selectedObjective: '',
			  	optionsUsers: [],
			  	parentVision: '',
			  	selectedMasterGoal: '',
			  	optionsMasterGoals: [],
			  	selectedPrivacy: 0,
			  	optionsPrivacy: [{id: 1, name: this.$t('general.public'), key: 'public', value: 0}, {id: 2, name: this.$t('general.private'), key: 'private', value: 1}],
                intervalDays: {},
                viewSelectUser: true,
                subModalShow: false,
                dropdownActive: '',
                show_subm_modal: {
                	confirm_close: false,
                	calendar_period: false,
                	error_max_leaders: false
                },
				errorsBe: {},
				fileUpload: null,
				fileError: '',
				savedFile: {},
				awaitingFilesForRemove: [],
				arrayTranslations: [
					'objective.title_edit',
					'objective.title_new',
					'objective.name',
					'objective.placeholder_name',
					'objective.user',
					'key-result.form.add_user',
					'objective.placeholder_user',
					'objective.no-results',
					'general.empty-list',
					'objective.master_goal',
					'objective.placeholder_vision',
					'master_goal.private',
					'objective.privacy_type',
					'objective.description',
					'objective.placeholder_description',
					'objective.period',
					'key-result.form.select-days',
					'key-result.form.start-date',
					'key-result.form.end-date',
					'days',
					'objective.edit_button',
					'objective.save_button',
					'btn-submit.loading',
					'btn-submit.success',
					'btn-submit.error',
					'objective.edit_button',
					'objective.save_button',
				],
				helpData1: this.$store.getters['help_links/currentLink']('objective_modal_1'),
				helpData2: this.$store.getters['help_links/currentLink']('objective_modal_2'),
				helpData3: this.$store.getters['help_links/currentLink']('objective_modal_3'),
				disabledDatesUser: []
			};
		},
		props:{
			model: String,
			requiredData: Object,
			fromPage: String,
			canAddMasterGoalsInline: String
		},
		components: {
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			ConfirmClose,
			ObjectivePeriodModal,
			IconDate,
			IconMasterGoals,
			IconPublic,
			IconPrivat,
			IconPersonal,
			IconPrivacySquare,
			IconLevelUp,
			IconQuestion,
			IconAward,
			IconUpload,
			ErrorMaxLeaders
		},
		validations: {
			objective: {
				name: {required},
				dateRange:{
					start_date: {required},
					end_date: {required}
				}
			},
			selectedUser: {required},
			selectedPrivacy: {required}
		},
		async mounted() {
			if(this.model == ''){
				this.editAction = false
			}else{
				await this.getObjectiveData()
			}

			await this.getUsers()
			await this.getMasterGoals()

			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	date_range: false,
	                	confirm_close: true
		            }
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
            });

			this.$root.$on("refreshMasterGoalTreeForObjectiveModal", (master_goal_detail) => {
				this.objective.master_goal_id = master_goal_detail.id;
 				this.subModalShow = false;
 				$('.submodal-container').removeClass('active');
				this.getMasterGoals(true);
			});

			this.$root.$on("refreshUsersListOnObjectiveModal", (user_detail) => {
				this.objective.user_id = user_detail.id;
 				this.subModalShow = false;
 				$('.submodal-container').removeClass('active');
				this.getUsers(true);
			});

			this.$root.$on("visibilityHiddenObjective", (is_active) => {
				this.subModalShow = is_active;
				setTimeout(() => {
					if(is_active){
						$('.submodal-container').addClass('active');
					}else{
						$('.submodal-container').removeClass('active');					
					}
				}, 0)
			});
		},
		methods: {
			async getObjectiveData(){
				await axios.get('/objectives/'+ this.model +'/edit').then(({data}) => {
					this.objective = data.data
					this.disabledPeriod = false
				})
				.then(() => {
					this.objective.dateRange = { start_date: this.objective.start_date, end_date: this.objective.end_date}
					this.checkObjective()
					this.editAction = true
				})
			},
			async getUsers(newWithUser = false){
				await axios.get(`/instances/${this.$auth.user().instance_id}/filter`, { params: { users: true } })
				.then(({data}) => {
					this.optionsUsers = data.data.users
					if(this.editAction || newWithUser){
						this.selectedUser = this.optionsUsers.find(el => el.id == this.objective.user_id);
					}
				});
			},
			async getMasterGoals(newWithMasterGoal = false){
				await axios.get('/master-goals')
				.then(({data}) => {
					this.optionsMasterGoals = data.data ? data.data : []

					if(this.editAction  || newWithMasterGoal){
						if(this.objective.master_goal_id){
							this.selectedMasterGoal = this.optionsMasterGoals.find(el => el.id == this.objective.master_goal_id);
						}
					}

					if(this.$auth.user().is_manager){
						var optionsDisabledManager = this.optionsMasterGoals.filter(el => el.disable_for_manager == true)

						optionsDisabledManager.forEach(element => {
							this.optionsMasterGoals.find(el => el.id == element.id).$isDisabled = true
						}) 
					}

					if(!(Object.keys(this.requiredData).length === 0)){
						this.selectedMasterGoal = this.optionsMasterGoals.find(el => el.id == this.requiredData.id);
					}
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
        					this.$refs.first_input.focus()
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	date_range: false,
	                	confirm_close: true
		            }
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(type){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			saveModifications(e){
				var btnSubmit = $('.button-submit')
				btnSubmit.attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					var btnSubmitLoader = $('.button-submit .loader')
					var btnSubmitText = $('.button-submit .text')

					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					if(this.editAction){
						this.updateObjective(e)
					}else{
						this.storeObjective(e)
					}
				}else{
					btnSubmit.attr('disabled', false)
				}
			},
			storeObjective(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				let objData = new FormData();
				objData.append('name', this.objective.name);
				objData.append('description', this.objective.description);
				objData.append('user_id', this.selectedUser.id);
				objData.append('start_date', this.moment(this.objective.dateRange.start_date).format('YYYY-MM-DD'));
				objData.append('end_date', this.moment(this.objective.dateRange.end_date).format('YYYY-MM-DD'));
				objData.append('related_objectives', this.selectedObjective.id);
				objData.append('master_goal_id', this.selectedMasterGoal ? this.selectedMasterGoal.id : '');
				objData.append('is_private', this.selectedPrivacy);
				objData.append('reward', this.objective.reward.name ? this.objective.reward.name : '');
				if(this.fileUpload){
					objData.append('file', this.fileUpload);
				}

				axios.post('/objectives/store', objData)
					.then(({data}) => {
					btnSubmitLoader.addClass('finish')
					this.refreshPage()
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							this.$emit("toggle_modal");
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('objective.edit_button') : this.$t('objective.save_button'))
						}, 1000)
					}, 300)

					if(error.response && error.response.data.errors.hasOwnProperty('leader_limit')){
						this.subModalShow = true
						this.show_subm_modal.error_max_leaders = true
						setTimeout(() => {
							$('.submodal-container').addClass('active');
							setTimeout(() => {
								$('#submodal_error_max_leaders').addClass('active');
							}, 200);
						}, 0);
					}
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			updateObjective(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				let objData = new FormData();
				objData.append('_method', 'PATCH')

				objData.append('name', this.objective.name);
				objData.append('description', this.objective.description ? this.objective.description : '' );
				objData.append('user_id', this.selectedUser.id);
				objData.append('start_date', this.moment(this.objective.dateRange.start_date).format('YYYY-MM-DD'));
				objData.append('end_date', this.moment(this.objective.dateRange.end_date).format('YYYY-MM-DD'));
				objData.append('related_objectives', this.selectedObjective ? this.selectedObjective.id : '');
				objData.append('master_goal_id', this.selectedMasterGoal ? this.selectedMasterGoal.id : '');
				objData.append('is_private', this.selectedPrivacy);
				objData.append('reward', this.objective.reward.name ? this.objective.reward.name : '');
				if(this.fileUpload){
					objData.append('file', this.fileUpload);
				}

				axios.post('/objectives/' + this.objective.slug, objData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
				.then(({data}) => {
					btnSubmitLoader.addClass('finish')
					this.refreshPage()
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							this.$emit("toggle_modal");
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('objective.edit_button') : this.$t('objective.save_button'))
						}, 1000)
					}, 300)

					if(error.response && error.response.data.errors.hasOwnProperty('leader_limit')){
						this.subModalShow = true
						this.show_subm_modal.error_max_leaders = true
						setTimeout(() => {
							$('.submodal-container').addClass('active');
							setTimeout(() => {
								$('#submodal_error_max_leaders').addClass('active');
							}, 200);
						}, 0);
					}
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			showModal(type, model = false, requiredData = false, userData = false){
				if(model){
					this.hideDropdown()
				}
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'objective');
			},
			changeDisablesDate(value){
				this.disabledDatesFinish.to = value ? value : ''
				this.disabledDateFinish = value ? false : true
			},
			checkObjective(){
				var startDate = this.moment(this.objective.dateRange.start_date)
				var endDate = this.moment(this.objective.dateRange.end_date)

				var diff_days = endDate.diff(startDate, 'days')

				var workDays = this.addWeekdays(startDate, diff_days); 

				this.intervalDays = {
					startDate: startDate,
					endDate: endDate,
					totalDays: diff_days,
					workDays: workDays
				}

				this.totalDaysRange = diff_days + 1

				this.selectedPrivacy = this.objective.is_private
			},
			addWeekdays(startDate, days) {
			  var date = this.moment(startDate); // use a clone
			  var working_days = 0;
			  while (days >= 0) {
			    date = date.add(1, 'days');
			    // 6 is Saturday, 7 is Sunday
			    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
			      working_days += 1;
			    }
			    days -= 1;
			  }
			  return working_days;
			},
			checkInputs(){
				var notEmpty = false

				if(this.objective.name != '' 
					|| this.objective.description != '' 
					|| this.objective.dateRange.start_date != ''
				){
					notEmpty = true
				}

				return notEmpty
			},
			showSubModal(type){
				this.subModalShow = true
				this.show_subm_modal[type] = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_' + type).addClass('active');
					}, 200);
                }, 0);
			},
			closeSubModal(type){
				$("#submodal_" + type).removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			getIntervalData(value){
				this.closeSubModal('calendar_period')
				this.objective.dateRange.start_date = JSON.parse(value).start_date
				this.objective.dateRange.end_date = JSON.parse(value).end_date

				var startDate = this.moment(this.objective.dateRange.start_date)
				var endDate = this.moment(this.objective.dateRange.end_date)

				var diff_days = endDate.diff(startDate, 'days')

				var workDays = this.addWeekdays(startDate, diff_days); 

				this.intervalDays = {
					startDate: startDate,
					endDate: endDate,
					totalDays: diff_days,
					workDays: workDays
				}

				this.totalDaysRange = diff_days + 1
			},
			clearRange(){
				this.objective.dateRange = {start_date: '',end_date: '',}
			},
			refreshPage(){
				switch (this.fromPage) {
					case 'objectives':
						this.$root.$emit('refreshObjectivesList')
						break;
					case 'objective':
						this.$root.$emit('refreshObjectivesList')
						break;
					case 'tree':
						this.$root.$emit('refreshMasterGoalTree')
						break;
				}
			},
			dropdownIsOpen(str) {
				this.dropdownActive = str; 
			},
			fullName({first_name, last_name, email}){
				return `${first_name} ${last_name} ${email}`
			},
			focusRequired(type){
				$('#' + type).addClass('shake-anim')
				setTimeout(() => {
					$('#' + type).removeClass('shake-anim')
				}, 400)
			},
			handleUserSelected(user){
				this.disabledPeriod = false;
				axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/${user.slug}/vacation-dates`, { params: { start_date: moment().format('YYYY-MM-DD'), end_date: moment().add(1, 'years').format('YYYY-MM-DD') } })
				.then(({data}) => {
					this.disabledDatesUser = data.data;
				})
			},
			onFileChange(e) {
				this.fileError = ''

				const files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				const file = files[0];
				
				if (file.size > 50 * 1024 * 1024) {
					this.fileError = 'The file is too large. Please choose a file that is smaller than 50MB.';
					this.fileUpload = null;

					const input = document.getElementById('fileUploaded');
					if (input) {
						input.value = null;
					}
					return;
				}

				this.fileUpload = file;
			},
			onDeleteFile() {
				if(this.objective.reward.id){
					axios.delete(`/common-files/${this.objective.reward.id}`)
				}

				this.fileUpload = null;
				this.objective.reward.filename = null;
				const input = document.getElementById('fileUploaded');
				if (input) {
					input.value = null;
				}
			}
		}
	};
</script>