<template>
	<div class="template-tbfdigital">
        <sidebar v-if="$resize && $mq.above(781)"/>
		<listUpdates v-if="$resize && $mq.above(821)"/>
		<div class="container-tbf">
			<navbar />
			<div class="container-content">
                <slot></slot>
			</div>
            <sidebar-mobile v-if="$resize && $mq.below(780)"/>
		</div>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>

            <modal-payment-error id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
            <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
            <modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>
            <modal-settings-mobile id="modal_settings_mobile" v-if="show_modal.settings_mobile" @close_modal="closeModal('settings_mobile')"/>
            <modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
            <modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
            <modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
        </div>
	</div>
</template>

<script>
    import Sidebar from '../General/Sidebar.vue'
    import Navbar from '../General/Navbar.vue'
    import SidebarMobile from "../General/SidebarMobile"
    import ListUpdates from "../Pages/System/ListUpdates"
    import ModalPaymentError from "../Modals/PaymentError"
    import ModalMissionVision from "../Modals/MissionVision"
    import ModalSettingsMobile from "../General/SidebarSettingsMobile"
    import ModalContact from "../Modals/Contact"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalNotifications from "../Modals/Notifications"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"

    export default {
        data() {
            return {
                show_overflow: false,
                show_modal: {
                    mission_vision: false,
                    notifications: false,
                    settings_mobile: false,
                    contact: false, 
                    webinar_tbf: false,
                    payment_error: false,
                    change_subscription: false
                },
                data: {}
            }
        },
        components: {
            Sidebar,
            Navbar,
            SidebarMobile,
            ListUpdates,
            ModalPaymentError,
            ModalMissionVision,
            ModalSettingsMobile,
            ModalContact,
            ModalWebinarTehnic,
            ModalNotifications,
            ModalChangeSubscription
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.checkLanguage;
        },
        methods: {
            closeModals(){
                this.closeModal()
            },
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            },
			checkLanguage(){
				if(this.$auth.check()){
					this.$i18n.locale = this.$auth.user().language
					this.$cookies.set('language', this.$auth.user().language, '1m')
				}else if(this.$cookies.get('language')){
					this.$i18n.locale = this.$cookies.get('language')
				}
			}
        }
    }
</script>