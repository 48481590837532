<template>
	<table>
		<tr>
			<td
				:colspan="treeData.children ? treeData.children.length * 2 : 1"
				:class="{ parentLevel: treeData.children, extend: checkShowChildren(treeData) && treeData.extend }">
				<div :class="{ node: true }">
					<!-- PARENT NODE -->
					<div class="box-tbf" @click="$emit('click-node', treeData)" v-if="treeData.type == 'master-goal'">
						<div class="upper-contain">
							<div class="vision-title-wrapper">
								<div class="vision-title" v-if="treeData.type == 'master-goal'">
									{{ $t("tree-chart.title-master-goal") }}
								</div>
								<div class="vision-action">
									<div class="dropdown add-dropdown" v-if="treeData.rights.create || treeData.rights.create_objective">
										<div
											class="add-item-button"
											:id="'dropdownAddTreeGoal' + treeData.slug"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false">
											<plus-icon class="plus-icon" />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownAddTreeGoal' + treeData.slug">
											<div
												class="dropdown-item"
												@click="showModal('master_goal', false, { id: treeData.id }, false)"
												v-if="treeData.rights.create">
												{{ $t("tree-chart.add_mastergoal") }}
											</div>
											<div
												class="dropdown-item"
												@click="
													$auth.user().rights.can_create_objective
														? showModal('objective', false, treeData, false)
														: showModal('cant_create_more_objective', false, treeData, false)
												"
												v-if="treeData.rights.create_objective">
												{{ $t("tree-chart.add_objective") }}
											</div>
										</div>
									</div>

									<div class="vision-edit" v-if="treeData.rights.edit || treeData.rights.delete">
										<div class="dropdown edit-item-dropdown">
											<div
												class="edit-item-button"
												:id="'dropdownTreeGoal' + treeData.slug"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false">
												<editDotsIcon />
											</div>
											<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownTreeGoal' + treeData.slug">
												<div
													class="dropdown-item"
													@click="showModal('master_goal', treeData.slug, { id: treeData.master_goal_id })"
													v-if="treeData.rights.edit">
													{{ $t("tree-chart.edit") }}
												</div>
												<div
													class="dropdown-item"
													@click="openModalV2('delete', treeData, 'master_goal', 'tree')"
													v-if="treeData.rights.delete">
													{{ $t("tree-chart.delete") }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="vision-sub-title">
								<span>{{ treeData.name ? treeData.name : $t("master_goal.private") }}</span>
							</div>
						</div>

						<div class="separator"></div>

						<div class="bottom-contain">
							<div class="amount">{{ parseInt(treeData.percentage_finished).toString() }}%</div>
							<div class="time">{{ treeData.remaining_days }} {{ $t("tree-chart.days") }}</div>
						</div>

						<div class="progress-bar-tbf">
							<div
								class="complete-progress-tbf"
								v-bind:class="{ blue: !treeData.has_one_overdue, overdue: treeData.has_one_overdue }"
								:style="'width: calc(2px + ' + (treeData.percentage_finished < 1 ? '2px' : treeData.percentage_finished + '%') + ');'"></div>
						</div>
					</div>

					<!-- CHILD LIGHT GREY -->
					<div class="box-tbf-goal" v-if="treeData.type == 'goal'">
						<div class="goal-avatar-wrapper">
							<div class="goal-avatar">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
									<img
										:src="
											treeData.user.avatar.lastIndexOf('gravatar') >= 0
												? treeData.user.avatar
												: `/build${treeData.user.avatar.substring(treeData.user.avatar.lastIndexOf('.ro') + 3)}`
										"
										class="avatar"
										v-if="treeData.user.avatar" />
									<div class="user-circle" v-else>
										<icon-user-settings />
									</div>

									<template slot="popover">
										<div class="simple-text">{{ treeData.user ? treeData.user.name : "" }}</div>
									</template>
								</v-popover>
							</div>
						</div>
						<div class="upper-contain">
							<div class="vision-title-wrapper">
								<div class="vision-title">
									{{ $t("tree-chart.title-goal") }}
								</div>
								<div class="vision-action">
									<v-popover
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf reward-popover"
										:delay="{ show: 200, hide: 0 }">
										<div class="icon" v-if="treeData.reward.filename || treeData.reward.name">
											<icon-reward />
										</div>

										<template slot="popover">
											<img
												:src="treeData.reward.file_path"
												v-bind:class="{ mrg_bot: treeData.reward.name != '' }"
												v-if="treeData.reward.file_path" />
											<div class="simple-text" v-if="treeData.reward.name">{{ treeData.reward.name }}</div>
										</template>
									</v-popover>

									<div class="dropdown add-dropdown" v-if="treeData.rights.create_key_result">
										<div
											class="add-item-button"
											:id="'dropdownAddKRTreeGoal' + treeData.slug"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false">
											<plus-icon class="plus-icon" />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownAddKRTreeGoal' + treeData.slug">
											<div
												class="dropdown-item"
												@click="showModal('key_result', false, treeData, false)"
												v-if="treeData.rights.create_key_result">
												{{ $t("tree-chart.addkeyresult") }}
											</div>
										</div>
									</div>

									<div
										class="vision-edit"
										v-click-outside="hideDropdown"
										v-if="treeData.rights.edit || treeData.rights.delete || treeData.rights.view">
										<div class="dropdown edit-item-dropdown">
											<!-- <div class="overlay-button" @click.stop="showDropdown(treeData.id)"></div> -->
											<div
												class="edit-item-button"
												:id="'dropdownTreeGoal' + treeData.id"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false">
												<editDotsIcon />
											</div>
											<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownTreeGoal' + treeData.id">
												<div class="dropdown-item" @click="viewObjective(treeData)" v-if="treeData.rights.view">
													{{ $t("tree-chart.view") }}
												</div>
												<div class="dropdown-item" @click="showModal('objective', treeData.slug)" v-if="treeData.rights.edit">
													{{ $t("tree-chart.edit") }}
												</div>
												<div
													class="dropdown-item"
													@click="openModalV2('delete', treeData, 'objective', 'tree')"
													v-if="treeData.rights.delete">
													{{ $t("tree-chart.delete") }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="goal-title">
								<v-popover
									offset="2"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									:disabled="!treeData.description"
									:delay="{ show: 200, hide: 0 }">
									<span>{{ treeData.name ? treeData.name : $t("objective.private") }}</span>

									<template slot="popover">
										<div class="simple-text">{{ treeData.description }}</div>
									</template>
								</v-popover>
							</div>
						</div>

						<div class="separator"></div>

						<div class="bottom-contain">
							<div class="amount">{{ parseInt(treeData.percent).toString() }}%</div>
							<div class="time">{{ diffDays(treeData.end_date) }} {{ $t("tree-chart.days") }}</div>
						</div>

						<div class="progress-bar-tbf">
							<div
								class="complete-progress-tbf"
								v-bind:class="{ red: treeData.status == 'overdue', blue: treeData.status != 'overdue', green: treeData.percent > 99 }"
								:style="'width: calc(2px + ' + (treeData.percent < 1 ? '2px' : treeData.percent + '%') + ');'"></div>
						</div>

						<div class="extend-handle-goal" :class="{ open: !isHidden }" @click="storeCookieActivity(treeData.id)"></div>

						<div class="extend-details-goal" v-if="!isHidden">
							<div class="goal-key-upper">
								<div class="section-title">
									{{ $t("tree-chart.title-results") }}
								</div>
								<div class="section-action">
									<div class="add-kr" @click="showModal('key_result', false, treeData, false)" v-if="treeData.rights.create_key_result">
										<plus-icon class="plus-icon" />
									</div>
									<div class="arrow-extend-up" @click="isHidden = !isHidden">
										<icon-arrow class="icon-arrow up" />
									</div>
								</div>
							</div>

							<div class="goal-keys-content">
								<div
									class="goal-key"
									v-for="(goal_key_result, index) in treeData.key_results"
									:key="index"
									v-if="!(hideItemsFinished.key_results && goal_key_result.status == 'finished')">
									<div class="goal-content">
										<div class="header-user-kr">
											<div class="left-part">
												<div class="icon">
													<img
														:src="
															goal_key_result.user.avatar.lastIndexOf('gravatar') >= 0
																? goal_key_result.user.avatar
																: `/build${goal_key_result.user.avatar.substring(
																		goal_key_result.user.avatar.lastIndexOf('.ro') + 3
																  )}`
														"
														class="avatar"
														v-if="goal_key_result.user.avatar" />
													<div class="user-circle" v-else>
														<icon-user-settings />
													</div>
												</div>
												<div class="user-data">
													<div class="name">{{ goal_key_result.user.name }}</div>
													<div class="user-role">{{ $t("users.role-name-" + goal_key_result.user.role) }}</div>
												</div>
											</div>
											<div
												class="kr-edit"
												v-click-outside="hideDropdown"
												v-if="treeData.rights.edit || treeData.rights.delete_key_result">
												<div class="dropdown edit-item-dropdown">
													<div
														class="edit-item-button"
														:id="'dropdownTreeGoalKey' + goal_key_result.id"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false">
														<editDotsIcon />
													</div>
													<div
														class="dropdown-menu dropdown-menu-right"
														:aria-labelledby="'dropdownTreeGoalKey' + goal_key_result.id">
														<div
															class="dropdown-item"
															@click="showModal('key_result', parseInt(goal_key_result.id).toString(), treeData)"
															v-if="treeData.rights.edit">
															{{ $t("tree-chart.edit") }}
														</div>
														<div
															class="dropdown-item"
															@click="openModalV2('delete', goal_key_result, 'kr', 'tree')"
															v-if="treeData.rights.delete_key_result">
															{{ $t("tree-chart.delete") }}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="header-kr">
											<v-popover
												offset="0"
												trigger="hover"
												placement="bottom"
												popoverBaseClass="popover-tbf"
												:disabled="!goal_key_result.description"
												:delay="{ show: 200, hide: 0 }">
												<div class="text">
													{{
														goal_key_result.name
															? goal_key_result.name.charAt(0).toUpperCase() + goal_key_result.name.slice(1)
															: $t("objective.kr_private")
													}}
												</div>

												<template slot="popover">
													<div class="simple-text">{{ goal_key_result.description }}</div>
												</template>
											</v-popover>
										</div>
										<div class="actions">
											<div class="row-data">
												<div
													class="notification"
													@click="
														goal_key_result.name
															? showModalKR(parseInt(goal_key_result.id).toString(), goal_key_result.uuid, 'updates')
															: ''
													"
													v-bind:class="{ disabled: !goal_key_result.name }">
													<v-popover
														offset="5"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf"
														:delay="{ show: 200, hide: 0 }">
														<div class="di-flex-center">
															{{ $t("tree-chart.updates") }}
															<span
																v-bind:class="{
																	warning:
																		goal_key_result.needs_an_update == 'today' || goal_key_result.needs_an_update == 'past',
																}"
																>{{
																	goal_key_result.needs_an_update == "today" || goal_key_result.needs_an_update == "past"
																		? "!"
																		: goal_key_result.key_results_logs_count
																}}</span
															>
														</div>

														<template slot="popover">
															<div class="simple-text" v-if="goal_key_result.needs_an_update == 'today'">
																{{ $t("key-result.tooltip-update-today") }}
															</div>
															<div class="simple-text" v-else-if="goal_key_result.needs_an_update == 'past'">
																{{ $t("key-result.tooltip-update-past") }}
															</div>
															<div class="simple-text" v-else-if="goal_key_result.needs_an_update">
																{{ $tc("key-result.tooltip-update-future", goal_key_result.needs_an_update) }}
															</div>
															<div class="simple-text" v-else-if="goal_key_result.status == 'upcoming'">
																{{ $t("key-result.tooltip-update-cant") }}
															</div>
															<div class="simple-text" v-else>{{ $tc("key-result.tooltip-updates-view") }}</div>
														</template>
													</v-popover>
												</div>
												<div class="notification percentage-not">
													<v-popover
														offset="10"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf"
														:delay="{ show: 200, hide: 0 }">
														<div class="progress-bar-tbf">
															<div
																class="complete-progress-tbf"
																v-bind:class="{
																	red: diffDays(goal_key_result.end_date) < 1 && goal_key_result.percentage_finished < 100,
																	green: goal_key_result.percentage_finished >= 100,
																}"
																:style="
																	'width: calc(2px + ' +
																	(parseInt(goal_key_result.percentage_finished) < 1
																		? '2px'
																		: parseInt(goal_key_result.percentage_finished).toString() + '%') +
																	');'
																"></div>
														</div>

														<template slot="popover">
															<div class="simple-text">
																{{
																	$t("progress_type_text." + goal_key_result.progress_type, {
																		to:
																			goal_key_result.target % 1 != 0
																				? $options.filters.numeral(goal_key_result.target, "0,0.00")
																				: $options.filters.numeral(goal_key_result.target, "0,0"),
																		unit: goal_key_result.unity,
																		from:
																			goal_key_result.start_value % 1 != 0
																				? $options.filters.numeral(goal_key_result.start_value, "0,0.00")
																				: $options.filters.numeral(goal_key_result.start_value, "0,0"),
																	})
																}}. {{ $t("progress_type_text.value") }}:
																{{
																	goal_key_result.current_value % 1 != 0
																		? $options.filters.numeral(goal_key_result.current_value, "0,0.00")
																		: $options.filters.numeral(goal_key_result.current_value, "0,0")
																}}
																{{ goal_key_result.unity }}
															</div>
														</template>
													</v-popover>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="goal-key" v-if="treeData.key_results.length == 0">
									<div class="goal-content">
										<div class="header-kr">
											<div class="icon">
												<key-results-icon />
											</div>
											<div class="text">
												{{ $t("tree-chart.no-key") }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="extend-handle-tbf" :class="{ open: !parentOpen }" @click="toggleExtend(treeData)" v-if="checkShowChildren(treeData)"></div>
			</td>
		</tr>
		<tr v-if="treeData.children && treeData.extend" class="children-row-wrapper">
			<td
				v-for="(children, index) in treeData.children"
				:key="index"
				colspan="2"
				:class="{ hasGoal: children.type == 'goal' }"
				class="childLevel"
				v-if="!(hideItemsFinished.objectives && children.type == 'goal' && children.status == 'finished')">
				<TreeChart
					:json="children"
					:hideItemsFinished="hideItemsFinished"
					:maxNumber="maxNumber"
					:zoomPage="zoomPage"
					@click-node="$emit('click-node', $event)" />
			</td>
		</tr>
	</table>
</template>

<script>
import EditDotsIcon from "../Icons/EditDots";
import PlusIcon from "../Icons/Plus";
import IconArrow from "../Icons/Arrow";
import KeyResultsIcon from "../Icons/KeyResults";
import IconUserSettings from "../Icons/UserSettings";
import IconReward from "../Icons/Award";

export default {
	data() {
		return {
			isHidden: true,
			treeData: {},
			showGoals: false,
			showPeople: false,
			parentOpen: false,
			clickedGoal: "",
		};
	},
	props: {
		json: Object,
		maxNumber: Number,
		zoomPage: Number,
		hideItemsFinished: Object,
	},
	name: "TreeChart",
	components: {
		EditDotsIcon,
		PlusIcon,
		IconArrow,
		KeyResultsIcon,
		IconUserSettings,
		IconReward,
	},
	watch: {
		json: {
			handler: function (Props) {
				let extendKey = function (jsonData) {
					var jsonExtend = localStorage.getItem("jsonExtend") ? JSON.parse(localStorage.getItem("jsonExtend")) : [];

					if (jsonExtend.find((el) => el.id == jsonData.id)) {
						jsonData.extend = jsonExtend.find((el) => el.id == jsonData.id).extend;
					} else {
						jsonData.extend = true;
						jsonExtend.push({ id: jsonData.id, extend: jsonData.extend });
					}
					localStorage.setItem("jsonExtend", JSON.stringify(jsonExtend));

					// if (Array.isArray(jsonData.children)) {
					// 	jsonData.children.forEach((c) => {
					// 		if(typeof c === 'object'){
					// 			extendKey(c);
					// 		}
					// 	});
					// }
					return jsonData;
				};
				if (Props) {
					if (typeof Props === "object") {
						this.treeData = extendKey(Props);
					}
				}
			},
			immediate: true,
		},
	},
	mounted() {
		this.$root.$on("toggleExtendAll", (valueExtend) => {
			this.treeData = extendKey2(this.treeData, valueExtend);
			this.$forceUpdate();

			var noCountExntend = localStorage.getItem("numberCountExtend");
			localStorage.setItem("numberCount", noCountExntend ? (noCountExntend = parseInt(noCountExntend) + 1) : 1);
		});

		function extendKey2(jsonData, extendValue) {
			var jsonExtend = localStorage.getItem("jsonExtend") ? JSON.parse(localStorage.getItem("jsonExtend")) : [];

			jsonData.extend = extendValue;
			if (jsonExtend.find((el) => el.id == jsonData.id)) {
				jsonExtend.find((el) => el.id == jsonData.id).extend = jsonData.extend;
			} else {
				jsonExtend.push({ id: jsonData.id, extend: jsonData.extend });
			}
			localStorage.setItem("jsonExtend", JSON.stringify(jsonExtend));

			return jsonData;
		}

		var noCount = localStorage.getItem("numberCount");
		localStorage.setItem("numberCount", noCount ? (noCount = parseInt(noCount) + 1) : 1);

		if (localStorage.getItem("numberCount") == this.maxNumber) {
			this.centerOrg();
		}

		if (this.$cookies.get("open_objectives")) {
			var arrayIdsObjectives = JSON.parse(this.$cookies.get("open_objectives"));
			if (arrayIdsObjectives.find((el) => el == this.treeData.id)) {
				this.isHidden = false;
			}
		}
	},
	methods: {
		toggleExtend: function (treeData) {
			var jsonExtend = localStorage.getItem("jsonExtend") ? JSON.parse(localStorage.getItem("jsonExtend")) : [];
			treeData.extend = !treeData.extend;
			jsonExtend.find((el) => el.id == treeData.id).extend = treeData.extend;
			localStorage.setItem("jsonExtend", JSON.stringify(jsonExtend));

			this.parentOpen = !this.parentOpen;
			this.$forceUpdate();
		},
		showGoalKeys() {
			this.showGoals = !this.showGoals;
		},
		showGoalPeople() {
			this.showPeople = !this.showPeople;
		},
		showDropdown(itemId) {
			if (itemId == this.clickedGoal) {
				$("#dropdownTreeGoal" + this.clickedGoal).dropdown("toggle");
				setTimeout(() => {
					this.clickedGoal = "";
				}, 0);
			} else {
				this.clickedGoal = itemId;
				setTimeout(() => {
					$("#dropdownTreeGoal" + this.clickedGoal).dropdown("toggle");
				}, 0);
			}
		},
		hideDropdown() {
			if (this.clickedGoal != "") {
				$("#dropdownTreeGoal" + this.clickedGoal).dropdown("toggle");
				setTimeout(() => {
					this.clickedGoal = "";
				}, 0);
			}
		},
		diffDays(end_date) {
			var a = moment().startOf("day");
			var b = moment(end_date).startOf("day");
			return b.diff(a, "days");
		},
		showModal(type, model = false, requiredData = false, userData = false) {
			this.hideDropdown();
			this.$root.$emit("open_modal", type, model, requiredData, userData);
		},
		showModalKR(model, uuid, type) {
			this.hideDropdown();
			this.$root.$emit("open_key_result_modals", model, uuid, type);
		},
		openModalV2(type, model = false, typeDelete = false, fromDelete = false) {
			this.hideDropdown();
			this.$root.$emit("open_modal_v2", type, model, typeDelete, fromDelete);
		},
		centerOrg() {
			var calcWidth =
				$(".scrollable-chart .center-box .parentLevel").first().children(".node").position().left * this.zoomPage -
				$(window).width() / 2 +
				($(".scrollable-chart .center-box .parentLevel").first().children(".node").width() / 2) * this.zoomPage +
				60;

			$(".scrollable-chart").animate(
				{
					scrollTop: $(".scrollable-chart").offset().top - $(window).height() / 2,
					scrollLeft: calcWidth,
				},
				300
			);
		},
		viewObjective(objective) {
			// let routeData = this.$router.resolve({ name: 'show-objective', params: { slug: objective.slug }});
			// window.open(routeData.href, '_blank');
			this.$root.$emit("open_modal", "details_objective", objective.slug);
		},
		checkShowChildren(treeData) {
			if (treeData.children && treeData.children.length > 0) {
				if (treeData.children.length == 1) {
					if (this.hideItemsFinished.objectives && treeData.children[0].type == "goal" && treeData.children[0].status == "finished") {
						return false;
					} else {
						return true;
					}
				} else {
					return true;
				}
			} else {
				return false;
			}
		},
		storeCookieActivity(itemId) {
			this.isHidden = !this.isHidden;
			var arrayIds = [];

			if (this.$cookies.get("open_objectives")) {
				arrayIds = JSON.parse(this.$cookies.get("open_objectives"));
			}

			if (!this.isHidden) {
				if (arrayIds.indexOf(itemId) === -1) {
					arrayIds.push(itemId);
				}
			} else {
				if (arrayIds.indexOf(itemId) !== -1) {
					arrayIds.splice(arrayIds.indexOf(itemId), 1);
				}
			}

			this.$cookies.set("open_objectives", JSON.stringify(arrayIds));
		},
	},
};
</script>
