<template>
	<div class="modal-sidebar notifications">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Notifications', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="top">
			<h1>{{ $t("notifications.title") }}</h1>
			<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
		</div>
		<div class="notification-header" v-if="loaded">
			<p v-bind:class="{ active: active_tab == 'activity' }" @click="changeTab('activity')">{{ $t("notifications.activity") }}</p>
			<p v-bind:class="{ active: active_tab == 'new' }" @click="changeTab('new')">
				{{ $t("notifications.new") }} (<span class="clickable-unread-counter">{{ countUnread }}</span
				>)
			</p>
		</div>

		<!-- Activitatea companiei -->
		<div class="notification-list clickable-notification-list" v-if="loaded && active_tab == 'activity'">
			<div class="notification-box" :id="'notification-box-' + notification.id" v-for="notification in activity_notifications" v-bind:class="{ 'is-link': notification.need_link }">
				<div class="left-side" v-if="$resize && $mq.above(600)"><icon-arrow :id="'notification-' + notification.id" class="icon-arrow right" /></div>
				<div class="center" @click="notification.need_link ? viewObjective(notification.entity_slug, notification, 'clickable', 'activity') : ''">
					<img v-if="notification.avatar != null && notification.type != 'objective_percent'" :src="notification.avatar" class="img-responsabile" />
					<div class="bell" v-else><bell /></div>
					<div class="content-notification">
						<p v-html="notification.name"></p>
						<span class="date">{{ notification.created_at | moment("D MMMM YYYY") }}</span>
					</div>
				</div>
				<div class="right-side" v-if="$resize && $mq.above(600)"></div>
			</div>
			<infinite-loading :identifier="1" @infinite="infiniteNotificationsActivity"
				><div slot="no-more"></div>
				<div slot="no-results"></div
			></infinite-loading>
		</div>
		<div class="no-notifications" v-if="Object.keys(activity_notifications).length == 0 && loaded && active_tab == 'activity'">
			<img src="/build/icons/bell2.svg" class="icon" />
			<p>{{ $t("notifications.all-good") }}</p>
			<span>{{ $t("notifications.no-notifications") }}</span>
		</div>

		<!-- Notificari click-uibile -->
		<div class="notification-list clickable-notification-list" v-if="loaded && active_tab == 'new'">
			<div class="notification-box" :id="'notification-box-' + notification.id" v-for="notification in notifications" v-bind:class="{ 'is-link': notification.need_link }">
				<div class="left-side" v-if="$resize && $mq.above(600)"><icon-arrow :id="'notification-' + notification.id" class="icon-arrow right" /></div>
				<div class="center" @click="notification.need_link ? viewObjective(notification.entity_slug, notification, 'clickable', 'personal') : ''">
					<img v-if="notification.avatar != null && notification.type != 'objective_percent'" :src="notification.avatar" class="img-responsabile" />
					<div class="bell" v-else><bell /></div>
					<div class="content-notification" v-bind:class="{ new: !notification.seen }">
						<p v-html="notification.name"></p>
						<span class="date">{{ notification.created_at | moment("D MMMM YYYY") }}</span>
					</div>
				</div>
				<div class="right-side" v-if="$resize && $mq.above(600)"></div>
			</div>
			<infinite-loading :identifier="2" @infinite="infiniteNotifications"
				><div slot="no-more"></div>
				<div slot="no-results"></div
			></infinite-loading>
		</div>
		<div class="no-notifications" v-if="Object.keys(notifications).length == 0 && loaded && active_tab == 'new'">
			<img src="/build/icons/bell2.svg" class="icon" />
			<p>{{ $t("notifications.all-good") }}</p>
			<span>{{ $t("notifications.no-notifications") }}</span>
		</div>
	</div>
</template>

<script type="text/javascript">
import IconPlus from "../Icons/Plus";
import Bell from "../Icons/Bell";
import IconArrow from "../Icons/Arrow";
import IconEditDots from "../Icons/EditDots";
import IconLargeBell from "../Icons/LargeBell";
import InfiniteLoading from "vue-infinite-loading";

export default {
	data() {
		return {
			loaded: false,
			notifications: {},
			activity_notifications: {},
			countUnread: 0,
			// read_notifications: {},
			active_tab: "new",
			view_more: 20,
			counter: 1,
			viewMoreUnread: 20,
			counterUnread: 1,
			arrayTranslations: ["notifications.title", "notifications.activity", "notifications.new", "notifications.all-good", "notifications.no-notifications"],
			procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
			organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
		};
	},
	props: {},
	components: {
		InfiniteLoading,
		IconPlus,
		Bell,
		IconArrow,
		IconEditDots,
		IconLargeBell,
	},
	async mounted() {
		// await this.getReadNotifications();
		await this.getNotifications();

		this.$root.$emit("clear_notifications");

		this.$root.$on("updateNotificationsList", (parsedData) => {
			if (parsedData.for_activity) {
				this.activity_notifications.unshift(parsedData);
			}
			if (parsedData.for_personal) {
				this.notifications.unshift(parsedData);
			}
		});
	},
	methods: {
		async getNotifications() {
			await axios
				.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`)
				.then(({ data }) => {
					this.notifications = data.data.notification_logs;
					this.countUnread = data.data.count_unread;
					this.loaded = true;
				})
				.finally(() => {
					this.active_tab = "new";
				});
		},
		getActivityNotifications() {
			axios
				.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list?type=activity`)
				.then(({ data }) => {
					this.activity_notifications = data.data.notification_logs;
				})
				.finally(() => {
					this.active_tab = "activity";
					this.loaded = true;
				});
		},
		infiniteNotifications($state2) {
			axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`, { params: { view_more: this.viewMoreUnread } }).then(({ data }) => {
				if (data.data.notification_logs.length) {
					this.counterUnread++;
					this.viewMoreUnread = 20 * this.counterUnread;
					this.activity_notifications.push(...data.data.notification_logs);
					$state2.loaded();
				} else {
					$state2.complete();
				}
			});
		},
		infiniteNotificationsActivity($state) {
			axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`, { params: { type: "activity", view_more: this.view_more } }).then(({ data }) => {
				if (data.data.notification_logs.length) {
					this.counter++;
					this.view_more = 20 * this.counter;
					this.activity_notifications.push(...data.data.notification_logs);
					$state.loaded();
				} else {
					$state.complete();
				}
			});
		},
		markAsRead(notification, tab_type) {
			axios
				.patch(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notifications/` + notification.id, {})
				.then(({ data }) => {
					this.getReadNotifications();
					this.getNotifications();
				})
				.catch((error) => {});
		},
		closeModal() {
			this.$emit("close_modal");
		},
		viewObjective(objectiveSlug, notification, notification_type, column) {
			var modal_type_kr = "";

			if (notification.frontend_type == "key_results" || notification.frontend_type == "logs") {
				modal_type_kr = "actualizari";
				this.$router.push({ name: "show-objective-modal", params: { slug: objectiveSlug, krId: notification.additional_data.key_result_id, modalType: modal_type_kr } });
			} else if (notification.frontend_type == "comments") {
				modal_type_kr = "comentarii";
				this.$router.push({ name: "show-objective-modal", params: { slug: objectiveSlug, krId: notification.additional_data.key_result_id, modalType: modal_type_kr } });
			} else if (notification.frontend_type == "tasks") {
				window.location.href = `${this.organigram_fe_link}/dashboard?open_task=${notification.entity_slug}&date=${notification.additional_data.date}`;
			} else if (notification.frontend_type == "meetings") {
				window.location.href = `${this.organigram_fe_link}/dashboard`;
			} else if (notification.frontend_type == "evaluations") {
				window.location.href = `${this.organigram_fe_link}/user/${objectiveSlug}`;
			} else if (notification.frontend_type == "role_responsibilities") {
				window.location.href = `${this.organigram_fe_link}/user/${itemSlug}`;
			} else if (notification.frontend_type == "announces") {
				window.location.href = `${this.organigram_fe_link}/announcements/${objectiveSlug}`;
			} else if (notification.frontend_type == "departments") {
				window.location.href = `${this.organigram_fe_link}/company`;
			} else if (notification.frontend_type == "procedures") {
				window.location.href = `${this.procedures_fe_link}/procedure/${objectiveSlug}`;
			} else if (notification.frontend_type == "processes") {
				window.location.href = `${this.procedures_fe_link}/process/${objectiveSlug}`;
			} else {
				this.$router.push({ name: "show-objective", params: { slug: objectiveSlug } });
			}

			if (column == "personal") {
				this.markAsRead(notification, notification_type);
			}
			this.closeModal();
		},
		changeTab(val) {
			if (val == "new") {
				this.getNotifications();
			} else {
				this.getActivityNotifications();
			}
		},
		showDropdown(itemId) {
			if (itemId == this.hoverTag) {
				$("#dropdownEditNotif" + this.hoverTag).dropdown("toggle");
				setTimeout(() => {
					this.hoverTag = "";
				}, 0);
			} else {
				this.hoverTag = itemId;
				setTimeout(() => {
					$("#dropdownEditNotif" + this.hoverTag).dropdown("toggle");
				}, 0);
			}
		},
		hideDropdown() {
			if (this.hoverTag != "") {
				$("#dropdownEditNotif" + this.hoverTag).dropdown("toggle");

				setTimeout(() => {
					this.hoverTag = "";
				}, 0);
			}
		},
	},
};
</script>
